<template>
	<DefaultTemplate>
		<h1 class="text-center">Sign Up</h1>
		<el-form label-position="top" :model="form" :rules="rules" ref="ruleForm" label-width="120px">
			<el-alert
				v-if="msgErrorValid !== ''"
				title="แจ้งเตือน"
				:closable="false"
				type="error"
				:class="{'shake animated': animated}"
				:description="msgErrorValid"
				show-icon>
			</el-alert>
			<el-form-item label="Email" prop="username">
				<el-input class="rounded" v-model="form.username"></el-input>
			</el-form-item>
			<el-row :gutter="10">
				<el-col :md="12">
					<el-form-item label="Password" prop="pass">
					<el-input type="password" :show-password="true" v-model="form.pass" class="rounded" autocomplete="off"></el-input>
				</el-form-item>
				</el-col>
				<el-col :md="12">
					<el-form-item label="Confirm password" prop="checkPass">
						<el-input type="password" :show-password="true" v-model="form.checkPass" class="rounded" autocomplete="off"></el-input>
					</el-form-item>
				</el-col>
			</el-row>
		</el-form>
		<button class="mg-y-5 w-100 btn-primary" @click="submit()" type="submit" round>Sign up</button>
		<p class="text-center"><router-link to="/sign-in">เข้าสู่ระบบ</router-link></p>
	</DefaultTemplate>			
</template>
<script>
import { HTTP } from'@/service/axios'
import { alertFailError } from'@/service/alertMsg'
import DefaultTemplate from '@/template/DefaultTemplate'
export default {
	components:{
		DefaultTemplate
	},
	data(){
		var validatePass = (rule, value, callback) => {
        if (value === '') {
          callback(new Error('Please input the password'));
        } else {
          if (this.form.checkPass !== '') {
            this.$refs.ruleForm.validateField('checkPass');
          }
          callback();
        }
      };
      var validatePass2 = (rule, value, callback) => {
        if (value === '') {
          callback(new Error('Please input the password again'));
        } else if (value !== this.form.pass) {
          callback(new Error('Two inputs don\'t match!'));
        } else {
          callback();
        }
      };
		return{
			animated:true,
			msgErrorValid:'',
			form:{
				username:'',
				pass:'',
				checkPass: '',
				userLevel:typeof this.$route.params.level != 'undefined' && this.$route.params.level == 'admin' ? 80 : 0 
			},
			rules: {
				username: [
					{ required: true, message: 'Please input email address', trigger: 'blur' },
					{ type: 'email', message: 'Please input correct email address', trigger: ['blur', 'change'] }
				],
				pass: [
					{ required: true, message: 'Please input password', trigger: 'blur' },
					{ min: 8, max: 15, message: 'Length should be 8 to 15', trigger: 'blur' },
					{ validator: validatePass, trigger: 'blur' }
				],
				checkPass: [
					{ required: true, message: 'Please confirm password', trigger: 'blur' },
					{ validator: validatePass2, trigger: 'blur' }
				]
        	}
		}
	},
	methods:{
		submit(){
			this.animated = false
			this.$refs['ruleForm'].validate((valid) => {
          		if (valid) {
          			HTTP.post('/user',this.form).then(res=>{
          		
          				if(res.data.success){
          					this.$message({
					          message: 'Register success',
					          type: 'success'
					        });
          					this.$router.push('/sign-in').catch(err => {})
          				}else{
          					this.animated = true
          					this.msgErrorValid = res.data.data
          				}
          			}).catch(e=>{
          				console.log('/user error',e)
          				this.alertFailError()
          			})
				}else{
					this.msgErrorValid = 'Please Fill all required field!'
				}
			})
		}
	}
}
</script>